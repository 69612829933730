<style type="text/css">
@import "~@/assets/css/account.css";
@import "~@/assets/css/common.css";
</style>
<style type="text/css" scoped>
.custom-file-label::after {
  display: none;
}
.cursonDrop {
  cursor: no-drop !important;
  background: #c7c6c6;
}
.form-control {
  padding: unset;
  border-radius: unset;
  border: unset;
}
/deep/ .el-input__inner {
  height: 100%;
}
.blue-font {
  background-color: rgba(73, 132, 210, 100);
  color: white;
  font-size: 14px;
  text-align: center;
}
</style>
<script>
import { authMethods } from "@/state/helpers";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import PersonHeader from "@/views/pages/examinee/person-header.vue";
import {
  verifyPhone,
  verifyEmail,
  resetCode,
  clearMyTimer,
  logOut,
  getUserInfo,
} from "@/libs/common.js";
import Verify from "@/components/verifition/Verify";
import { sendPhoneCodeNotUse, codeExpired, getcode } from "@/api/index.js";

import {
  userInfo,
  updateUserphone,
  updateUserEmail,
  updateUserPassword,
  checkPhoneCode,
} from "@/api/userInfo.js";

/**
 * PersonData--imgage
 */
export default {
  components: {
    Layout,
    PageHeader,
    PersonHeader,
    Verify,
  },
  data() {
    return {
      title: "个人资料完善",
      items: [
        {
          text: "资料完善",
        },
        {
          text: "账号信息",
          active: true,
        },
      ],
      verification: "", //验证码
      isShow: false, //验证码窗口
      userInfo: {},
      registerUser: {},
      showPhone: false,
      showEmail: false,
      telForm: {
        yddh: "",
        code: "",
      },
      emailForm: {
        email: "",
      },
      passWordForm: {
        ymm: "",
        xmm: "",
        qrmm: "",
      },
      imgCode: {
        uuid: "",
        img: "",
        code: "",
      },
      Countdown: 120,
    };
  },
  methods: {
    ...authMethods,
    getCode() {
      let formData = this.telForm;

      if (formData.yddh == this.userInfo.yddh) {
        this.$message("新手机号与原手机号相同");
        return;
      }
      if (this.Countdown == 120) {
        let res = verifyPhone(formData.yddh);
        if (res.isRight) {
          codeExpired({ mobilePhone: formData.yddh }).then((res) => {
            if (res.status) {
              if (res.data) {
                this.getImageCode();
                this.isShow = true;
              } else {
                this.$confirm("验证码尚未过期, 是否重新获取?", "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                })
                  .then(() => {
                    this.getImageCode();
                    this.isShow = true;
                  })
                  .catch(() => {
                    return;
                  });
              }
            }
          });
        } else {
          this.$message.error(res.errorMess);
        }
      }
    },
    changeXmm: function () {
      let res = this.chkPassword();
      if (!res.code) {
        this.$message.error(res.message);
        return;
      }
    },
    blurQrXmm: function () {
      let res = this.chkRePassword();
      if (!res.code) {
        // res.messsage
        this.$message(res.message);
        return;
      }
    },
    chkOldPassword: function () {
      var password = this.passWordForm.ymm;
      if (password.length >= 6) {
        return {
          code: true,
          message: "",
        };
      }
      if (password.length == 0) {
        return {
          code: false,
          message: "请输入原密码",
        };
      }
      return {
        code: false,
        message: "原密码长度小于6位",
      };
    },
    chkPassword: function () {
      var password = this.passWordForm.xmm;
      if (password.length == 0) {
        return {
          code: false,
          message: "请输入新密码",
        };
      }
      const regPassword =
        /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)^\S{6,16}$/;
      if (regPassword.test(password)) {
        return {
          code: true,
          message: "",
        };
      }
      return {
        code: false,
        message: "密码格式不正确(6-12位数字、字母、特殊符号2种以上组合)",
      };
    },
    chkRePassword: function () {
      var rePassword = this.passWordForm.qrmm;
      if (rePassword.length >= 6 && rePassword === this.passWordForm.xmm) {
        return {
          code: true,
          message: "",
        };
      }
      return {
        code: false,
        message: "两次密码不一致",
      };
    },

    update(type) {
      var _this = this;
      switch (type) {
        case "tel":
          _this.updateTel();
          break;

        case "email":
          _this.updateEmail();
          break;

        case "password":
          _this.updatePassword();
          break;

        default:
          break;
      }
    },
    updateEmail() {
      var _this = this;
      let formData = _this.emailForm;
      let obj = {
        message: "",
        type: "warning",
      };
      let res = verifyEmail(formData.email);
      if (res.isRight) {
        updateUserEmail(formData).then((res) => {
          if (res.status) {
            this.getInfo();
            this.showEmail = false;
            obj.message = res.message;
            obj.type = "success";
            this.emailForm.email = "";
          }
          _this.$message(obj);
        });
      } else {
        obj.message = res.errorMess;
        _this.$message(obj);
      }
    },
    updateTel() {
      var _this = this;
      let formData = _this.telForm;
      let obj = {
        message: "",
        type: "warning",
      };
      let res = verifyPhone(formData.yddh);
      if (res.isRight) {
        if (formData.code) {
          updateUserphone({
            mobilePhone: formData.yddh,
            phoneCode: formData.code,
          }).then((res) => {
            if (res.status) {
              clearMyTimer()
              this.Countdown = 120;
              this.getInfo();
              this.showPhone = false;
              obj.message = res.message;
              obj.type = "success";
              _this.$message(obj);
              _this.telForm.yddh = "";
              _this.telForm.code = "";
              return;
            }
          });
        } else {
          obj.message = "请输入验证码";
          _this.$message(obj);
        }
      } else {
        obj.message = res.errorMess;
        _this.$message(obj);
      }
    },
    updatePassword() {
      var _this = this;
      let res = {};
      res = _this.chkOldPassword();
      if (!res.code) {
        _this.$message({
          message: res.message,
          type: "warning",
        });
        return;
      }
      res = _this.chkPassword();
      if (!res.code) {
        _this.$message({
          message: res.message,
          type: "warning",
        });
        return;
      }
      res = _this.chkRePassword();
      if (!res.code) {
        _this.$message({
          message: res.message,
          type: "warning",
        });
        return;
      }
      updateUserPassword({
        ymm: _this.passWordForm.ymm,
        xmm: _this.passWordForm.xmm,
      }).then((res) => {
        if (res.status) {
          _this.$message({
            message: "修改成功",
            type: "success",
          });
          logOut();
        }
      });
    },
    // 获取图片验证码
    getImageCode() {
      var _this = this;
      getcode().then((res) => {
        if (res.status) {
          _this.imgCode.uuid = res.data.uuid;
          _this.imgCode.img = res.data.img;
        }
      });
    },
    checkImagecode() {
      let uuid = this.imgCode.uuid;
      let code = this.verification;
      if (code) {
        sendPhoneCodeNotUse({
          mobilePhone: this.telForm.yddh,
          uuid: uuid,
          code: code,
        }).then((res) => {
          this.verification = ''
          if (res.status) {
            this.isShow = false;
            resetCode(
              this.Countdown,
              () => {
                this.Countdown -= 1;
              },
              () => {
                this.Countdown = 120;
              }
            );
            this.$message({
              message: res.message,
              type: "success",
            });
          }
        });
        return;
      } else {
        this.$message("请先输入图片验证码");
        this.getImageCode();
      }
    },
    getInfo() {
      userInfo().then((res) => {
        if (res.status) {
          this.userInfo = res.data;
          this.saveInfo({ data: res.data });
        }
      });
    },
  },
  created() {
    this.getInfo();
    this.registerUser = JSON.parse(localStorage.getItem("userInfo"));
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 470px">
          <div class="card-body">
            <h4 class="card-title pb-3 border-dash">
              <span class="mr-2">个人账号管理</span>
            </h4>
            <div>
              <div class="site-content">
                <div class="module-header">
                  <div class="title">
                    <span class="text-dark">{{ userInfo.xm }}</span
                    ><span>（账号：{{ userInfo.sfzjh }}）</span>
                  </div>
                </div>

                <div class="module-body">
                  <div class="account-container">
                    <div class="accordion-panel">
                      <div class="account-left">
                        <h5>手机号</h5>
                        <p>
                          {{ userInfo.yddh ? userInfo.yddh : "暂无绑定手机号" }}
                        </p>
                      </div>
                      <div>
                        <b-button v-b-modal.change-phone variant="outline-info"
                          >更换</b-button
                        >
                        <b-modal
                          id="change-phone"
                          v-model="showPhone"
                          title="更换手机号"
                          title-class="font-18"
                          centered
                          class="person-modal"
                          hide-footer
                        >
                          <div class="title-tips" v-if="userInfo.yddh">
                            你当前手机号为 {{ userInfo.yddh }}，确认要修改吗？
                          </div>
                          <div class="title-tips" v-if="!userInfo.yddh">
                            当前未绑定手机号
                          </div>
                          <div class="item-box">
                            <span class="blue-font font-width">新手机号 </span
                            ><input
                              type="number"
                              placeholder="请输入新手机号码"
                              class="modal-input"
                              required
                              v-model="telForm.yddh"
                            />
                          </div>
                          <div class="item-box">
                            <span
                              class="blue-font font-width"
                              style="cursor: pointer"
                              :class="{ cursonDrop: Countdown != 120 }"
                              @click="getCode"
                              >{{
                                Countdown == 120
                                  ? "点击获取验证码"
                                  : Countdown + "s"
                              }} </span
                            ><input
                              type="email"
                              placeholder="请输入接收到的短信验证码"
                              class="modal-input"
                              required
                              v-model="telForm.code"
                            />
                          </div>
                          <div class="w-100">
                            <button
                              type="button"
                              class="btn btn-info change-btn"
                              @click="update('tel')"
                            >
                              确定修改
                            </button>
                          </div>
                        </b-modal>
                      </div>
                    </div>

                    <div class="accordion-panel">
                      <div class="account-left">
                        <h5>电子邮箱</h5>
                        <p>
                          {{
                            userInfo.dzxx
                              ? userInfo.dzxx
                              : "目前尚未添加，点击添加"
                          }}
                        </p>
                      </div>
                      <div>
                        <b-button
                          v-b-modal.change-email
                          variant="outline-info"
                          >{{ userInfo.dzxx ? "修改" : "添加" }}</b-button
                        >
                        <b-modal
                          id="change-email"
                          v-model="showEmail"
                          title="更改电子邮箱"
                          title-class="font-18"
                          centered
                          class="person-modal"
                          hide-footer
                        >
                          <div class="title-tips">
                            你可以非常方便的接收电子邮件通知
                          </div>
                          <div class="item-box">
                            <span class="blue-font font-width">邮箱地址</span
                            ><input
                              type="email"
                              placeholder="请输入电子邮箱地址"
                              class="modal-input"
                              required
                              v-model="emailForm.email"
                            />
                          </div>
                          <div class="w-100">
                            <button
                              type="button"
                              class="btn btn-info change-btn"
                              @click="update('email')"
                            >
                              确定修改
                            </button>
                          </div>
                        </b-modal>
                      </div>
                    </div>

                    <!-- <div class="accordion-panel">
                      <div class="account-left">
                        <h5>微信绑定</h5>
                        <p>目前尚未添加，点击添加</p>
                        <p>
                          <img
                            src="@/assets/images/person/fa-weixin.png"
                            alt=""
                            class="mr-2"
                          /><a href="#" style="color: #1b67cc">点击绑定微信</a>
                        </p>
                      </div>
                    </div> -->

<!--                    <div class="accordion-panel">-->
<!--                      <div class="account-left">-->
<!--                        <h5>修改密码</h5>-->
<!--                        <p>密码已设定，点击修改按钮可重置</p>-->
<!--                      </div>-->
<!--                      <div>-->
<!--                        <b-button-->
<!--                          v-b-modal.change-password-->
<!--                          variant="outline-info"-->
<!--                          >修改</b-button-->
<!--                        >-->
<!--                        <b-modal-->
<!--                          id="change-password"-->
<!--                          title="更改密码"-->
<!--                          title-class="font-18"-->
<!--                          centered-->
<!--                          class="person-modal"-->
<!--                          hide-footer-->
<!--                        >-->
<!--                          <form :model="passWordForm">-->
<!--                            <div class="item-box">-->
<!--                              <span class="blue-font font-width">原密码 </span-->
<!--                              ><input-->
<!--                                type="password"-->
<!--                                placeholder="请输入原密码"-->
<!--                                v-model="passWordForm.ymm"-->
<!--                                class="modal-input"-->
<!--                                required-->
<!--                              />-->
<!--                            </div>-->

<!--                            <div class="item-box">-->
<!--                              <span class="blue-font font-width">新密码 </span-->
<!--                              ><input-->
<!--                                type="password"-->
<!--                                placeholder="请输入新密码"-->
<!--                                v-model="passWordForm.xmm"-->
<!--                                class="modal-input"-->
<!--                                required-->
<!--                                @change="changeXmm"-->
<!--                              />-->
<!--                            </div>-->

<!--                            <div class="item-box">-->
<!--                              <span class="blue-font font-width"-->
<!--                                >确认新密码 </span-->
<!--                              ><input-->
<!--                                type="password"-->
<!--                                placeholder="请再次输入新密码"-->
<!--                                v-model="passWordForm.qrmm"-->
<!--                                class="modal-input"-->
<!--                                required-->
<!--                                @change="blurQrXmm"-->
<!--                              />-->
<!--                            </div>-->

<!--                            <div class="w-100">-->
<!--                              <button-->
<!--                                type="button"-->
<!--                                class="btn btn-info change-btn"-->
<!--                                @click="update('password')"-->
<!--                              >-->
<!--                                确定修改-->
<!--                              </button>-->
<!--                            </div>-->
<!--                          </form>-->
<!--                        </b-modal>-->
<!--                      </div>-->
<!--                    </div>-->

                    <div class="accordion-panel">
                      <div class="account-left">
                        <h5>最近登录</h5>
                        <p>
                          你最近一次登录系统是 {{ registerUser.zhdlsj }}
                          <span v-if="registerUser.zhdldq"
                            >来源于 {{ registerUser.zhdldq }}</span
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="isShow"
      title="获取验证码"
      title-class="font-18"
      centered
      class="person-modal"
      hide-footer
      style="width: 395px"
    >
      <div class="input-group" style="height: 45px">
        <div class="input-group-prepend">
          <span
            id="validationTooltipUsernamePrepend"
            class="input-group-text icon-box"
            ><img src="@/assets/images/person/icon/security.png" alt=""
          /></span>
        </div>
        <el-input
          id="code"
          type="text"
          placeholder="请输入验证码"
          aria-describedby="validationTooltipUsernamePrepend"
          class="form-control h-100"
          v-model="verification"
        />
        <img :src="imgCode.img" class="check-img" @click="getImageCode" />
      </div>
      <div class="w-100 mt-2">
        <button
          type="button"
          class="btn btn-info change-btn"
          @click="checkImagecode"
        >
          获取短信验证码
        </button>
      </div>
    </b-modal>
  </Layout>
</template>
