<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title pb-3 border-dash text-danger" style="font-family:'黑体', '微软雅黑', sans-serif;">
            特别说明：以下内容仅作为个人基本资料维护。修改个人基本资料不等同修改报名信息，报名信息一旦提交无法修改，更改报名信息须删除后重报。
          </h4>
          <div class="flexList person-header">
            <div class="d-flex w-20" v-if="showObj.default || showObj.zp">
              <div
                class="data-tips flexList"
                :class="{ active: page == 'img' }"
                @click="jumpPage('./personalImage')"
              >
                <i class="data-tips-icon mr-2 iconfont icon-picture-one"></i>
                <div class="tip-font">
                  <div class="fw600">照片认证</div>
                  <div class="font12">
                    必填
                  </div>
                </div>
              </div>
              <img
                src="@/assets/images/person/double-right.png"
                class="arrow-img"
              />
            </div>
            <div class="d-flex w-20" v-if="showObj.default || showObj.xl">
              <div
                class="data-tips flexList"
                :class="{ active: page == 'edu' }"
                @click="jumpPage('./personalEducation')"
              >
                <i class="data-tips-icon mr-2 iconfont icon-certificate"></i>
                <div class="tip-font">
                  <div class="fw600">学历信息认证</div>
                  <div class="font12">
                    {{ showObj.xl ? "必填" : "非必填" }}
                  </div>
                </div>
              </div>
              <img
                src="@/assets/images/person/double-right.png"
                class="arrow-img"
              />
            </div>
            <div class="d-flex w-20">
              <div
                class="data-tips flexList"
                :class="{ active: page == 'basic' }"
                @click="jumpPage('./personalData')"
              >
                <i class="data-tips-icon mr-2 iconfont icon-analysis"></i>
                <div class="tip-font">
                  <div class="fw600">基本资料</div>
                  <div class="font12">
                    非必填
                    <!--                    {{ showObj.default ? "必须，个人基本信息" : "非必填" }}-->
                  </div>
                </div>
              </div>
              <img
                src="@/assets/images/person/double-right.png"
                class="arrow-img"
              />
            </div>
            <div class="d-flex w-20">
              <div
                class="data-tips flexList"
                :class="{ active: page == 'work' }"
                @click="jumpPage('./personalWork')"
              >
                <i
                  class="data-tips-icon mr-2 iconfont icon-if-architecture-alt"
                ></i>
                <div class="tip-font">
                  <div class="fw600">工作经历及社会关系</div>
                  <div class="font12">
                    非必填
                    <!--                    {{ showObj.default ? "完善工作经历、家庭成员" : "非必填" }}-->
                  </div>
                </div>
              </div>
              <img
                src="@/assets/images/person/double-right.png"
                class="arrow-img"
              />
            </div>
            <div class="d-flex w-20">
              <div
                class="data-tips flexList"
                :class="{ active: page == 'user' }"
                @click.stop="jumpPage('./recordList')"
              >
                <i
                  class="data-tips-icon mr-2 iconfont icon-a-md-lock_outlineCopy"
                ></i>
                <div class="tip-font">
                  <div class="fw600">开始报名</div>
                  <div class="font12">
                    {{ showObj.default ? "个人信息完善成功，前往报名" : "" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showObj: {
        default: true,
      },
    };
  },
  props: {
    page: {
      type: String,
      default: "",
    },
    show: {
      type: String,
      default: "",
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    jumpPage(path) {
      if (this.disable) {
        this.$confirm(
          "白底证件照系统审核未通过, 需要上传身份证扫描件",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        );
        return;
      }
      if (this.show) {
        this.$router.push({
          path: path,
          query: {
            show: this.show,
          },
        });
        return;
      }
      this.$router.push(path);
    },
  },
  mounted() {
    if (this.show) {
      let list = this.show.split(",");
      this.showObj = {};
      list.forEach((v) => {
        this.showObj[v] = true;
      });
    }
  },
};
</script>

<style>
.data-tips {
  cursor: pointer;
}
</style>
